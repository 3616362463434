import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "Data Poetry: Small Data's Power of Persuasion",
  date: "2017-06-21",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20170621-0.jpg",
  path: "2017-06-21-Data-Poetry",
  teaserText: "Our ability to probe has expanded greatly through more powerful technology, limitless amounts of data, algorithmic-based predictive processes and tools, and above all, through more networked and collaborative platforms."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Data Poetry: Small Data's Power of Persuasion" description="Our ability to probe has expanded greatly through more powerful technology, limitless amounts of data, algorithmic-based predictive processes and tools, and above all, through more networked and collaborative platforms." author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="p" components={components}>{`We love solving riddles. We’re hardwired to question all aspects of our lives: from our day-to-day quandaries to the bigger issues around our tiny existence in the cosmos. By probing, asking and tinkering, we solve for things that make life better and keep civilization advancing.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our ability to probe has expanded greatly through more powerful technology, limitless amounts of data, algorithmic-based predictive processes and tools, and above all, through more networked and collaborative platforms. The result is big data that can help us forecast and track scenarios, challenges and opportunities.`}</MDXTag>
      <MDXTag name="p" components={components}>{`While we now have a lot of data, a lot of it is still unstructured, messy and malleable. We've become addicted to it, seemingly finding it in it all the answers we seek. To do this, we’ve hired data scientists the world over to make sense of it and transformed the educational/training needs to prize the sciences over the arts and humanities.`}</MDXTag>
      <MDXTag name="p" components={components}>{`As data becomes more abundant, however, we will reach a point where its value becomes increasingly commoditized and so too will the base of bots and people crunching and scrapping it. All this while still coming short as pure tech and data have yet to fully replicate the complex processing and emotive power of the human brain.`}</MDXTag>
      <ImageLandscape imageSrc="20170621-1.jpg" caption="Spotify Campaign" />
      <MDXTag name="p" components={components}>{`Instead, we will see the rise in demand for smarter, smaller data that seeks to solve specific problems, along with a better human interpretation of the data. It’s this mix of better systems and better right/left side of the brain talent that will yield new breakthroughs.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The commercial impetus for this shift is the human need, in this digital era, for human touch in data-facilitated seamless transactions. This is what will allow brands to build consumer-emotional commitment that can provide a defense from ongoing external disruptions.`}</MDXTag>
      <ImageLandscape imageSrc="20170621-2.jpg" caption="Moleskine's Digital Notebook" />
      <MDXTag name="p" components={components}>{`The following are some ways in which we expect small data to impact people, businesses and brands:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Focus on more specific questions whose answers will give way to more powerfully relevant, personal and breakthrough products`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Consider human-based smart models such as Stitch Fix delivering smart personalization and guidance`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Think also next gen innovation managers that can speak both to data and human-centric, design-thinking approaches, tonality and stories`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Imagine a higher form of customer service with lifestyle associates that know your style, their craft and how to inspire you`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`A curriculum and training approach that blends more seamlessly the arts, humanities and the sciences`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`A greater focus on creative problem solving and interpretation to make sense of conflicting data`}</MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    